const flowTypeEmail = 'Email';
const flowTypeGeneral = 'General flow';
export const DEFAULT_SPLIT_POINT = 'onboarding';
export const DEFAULT_PLATFORM_TYPE = 'web';
export type FlowType = 'Email' | 'General flow';
export type SplitTrafficMode = 'onboarding' | 'payment';
export type PlatformType = 'web' | 'mobile';

export const FLOW_TYPES = ['onboarding', 'payment'];
export const CATEGORIES_TYPES = ['select', 'info', 'loaders', 'input', 'modals', 'payment_info', 'products', 'checkouts'];
export const QA_EMAILS = ['wellfunnel_aqa@welltech.com'];

type FlowTypeObject = {
    name: FlowType;
    value: string;
};

export const flowTypes: FlowTypeObject[] = [
    { name: flowTypeEmail, value: 'email' },
    { name: flowTypeGeneral, value: 'general' },
];

export const platformTypes = [
    { name: 'Web', value: 'web' },
    { name: 'Mobile', value: 'mobile' },
];

export const headerTypes = [
    { name: 'Off', value: 'off' },
    { name: 'Logo left', value: 'logo_left' },
    { name: 'Progress', value: 'progress' },
    { name: 'Sign in', value: 'sign_in' },
    { name: 'Skip', value: 'skip' },
    { name: 'Logo left on the plate', value: 'logo_left_plate' },
    { name: 'Logo centered', value: 'logo_centered' },
    { name: 'Progress redesign', value: 'progress_redesign' },
];

export const productTag = [
    { name: 'Main', value: 'main' },
    { name: 'Offer', value: 'offer' },
    { name: 'Upsell', value: 'upsell' },
];
